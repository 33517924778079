import { REQUIRED } from "@/util/validation-rules"
import { DATE_DEFAULT_REGXP } from "@/util/const"

export default class CMCourseValidator {
  constructor(data, validator, examData) {
    this.data = data
    this.validator = validator
    this.examData = examData
  }
  
  validate () {
    this.validator.resetFields()
    return this.isValid()
  }

  isValid() {
    this.validateDateName()
    // this.validateDate()
    this.validateCity()
    this.validateVisibleOnLandingField()
    this.validateReservationOptions()
    this.validateLicenseType()
    // this.validateRequired('name', this.data.name)
    return this.validator.isValid()
  }

  validateVisibleOnLandingField() {
    if (this.data.isVisibleOnLanding === null) {
      this.validator.invalidField('isVisibleOnLanding', 'Visibility on landing should be chosen')
    }
  }

  validateReservationOptions() {
    if(this.data.reservableLicenseTypes.length === 0) {
      this.validator.invalidField('reservableLicenseTypes', 'At least one reservation option should be chosen')
    }
  }

  validateDateName() {
    if(!this.data.date && !this.data.name) {
      this.validator.invalidField('date', 'Date or Name should be filled')
      this.validator.invalidField('name', 'Date or Name should be filled')
      return
    }
    if(this.data.date) this.validateDate()
  }

  validateDate() {
    if(!(DATE_DEFAULT_REGXP.test(this.data.date))) 
      this.validator.invalidField(
        'date', 
        'Date should be in DD-MM-YYYY format'
      )
  }

  validateRequired(field, data) {
    let match = REQUIRED(data)
    if(match === true) return true
    this.validator.invalidField(field, match)
    return false
  }

  validateNotes() {
    if(!this.validateRequired('notes', this.data.notes)) return
  }

  validateCity() {
    if(!this.validateRequired('city', this.data.city)) return
  }

  validateLicenseType() {
    if(!this.examData) return;
    if(!this.examData.licenseType || this.examData.licenseType.length === 0) {
      this.validator.invalidField('licenseType', 'At least one license type should be chosen')
    }
  }
}