<script>
import Validator from "./CMCourseValidator"
import ExamConfigValidator from "../../classes/CMExamConfigValidator"
import FormErrors from '@/util/form-errors'

export default {

  render() {
    return this.$scopedSlots.default({
      validationBeforeSend: this.validationBeforeSend,
    })
  },

  props: {
    formErrors: Object
  },

  methods: {
    validationBeforeSend(payload) {
      this.formErrors.resetFields()
      new Validator(payload.data, this.formErrors).isValid()
      new ExamConfigValidator(payload.examData, this.formErrors).isValid()
      if(!this.formErrors.isValid()) {
        this.$notify({text:'Invalid fields', type: 'error'})
        return
      }
      this.$emit('validated', {...payload, validator: this.formErrors})
    },
  }
}
</script>
